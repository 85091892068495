.form {
  width: 425px;
  height: auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px 0px #333;
  margin: calc(50vh - 220px) auto;
  padding: 20px 30px;
  max-width: calc(100vw - 40px);
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  position: relative;
}

.title {
  margin: 10px 0;
  padding-bottom: 10px;
  width: 180px;
  /* color:#78788c; */
  color: #303f9f;
  /* border-bottom:3px solid #78788c; */
  text-align: center !important;
}

.input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: none;
  outline: none;
  resize: none;
  border: 0;
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s;
  /* border-bottom:2px solid #bebed2 */
}

.textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: none;
  /* outline:none; */
  /* resize:none; */
  border: 0;
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s;
  border-bottom: 2px solid #bebed2;
}
.input:focus {
  border-bottom: 2px solid #78788c;
}

.text:before {
  content: attr(type);
  display: block;
  margin: 28px 0 0;
  font-size: 14px;
  color: #5a5a5a;
}

.button {
  float: right;
  padding: 8px 12px;
  margin: 8px 0 0;
  font-family: "Montserrat", sans-serif;
  border: 2px solid #78788c;
  background: 0;
  color: #5a5a6e;
  cursor: pointer;
  transition: all 0.3s;
}

.button:hover {
  background: #78788c;
  color: #fff;
}

.div {
  content: "Hi";
  position: absolute;
  bottom: -40px;
  right: 80px;
  /* background:#50505a; */
  color: #fff;
  /* width:320px; */
  padding: 16px 4px 16px 0;
  border-radius: 6px;
  font-size: 13px;
  /* box-shadow:10px 10px 40px -30px #F00 */
  /* opacity: 0; */
  /* display: none; */
}
